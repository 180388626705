import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import BannerAbout from '../components/BannerAbout'
import Layout from '../components/layout'

import pic08 from '../assets/images/pic08.jpg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'

const About = (props) => (
    <Layout>
        <Helmet
            title="About us"
            meta={[
                { name: 'description', content: 'About us Page' },
                { name: 'keywords', content: 'About us Page' },
            ]}
        >
        </Helmet>

        <BannerAbout />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Company</h2>
                    </header>
                    <p><b>Nubizzi</b> is a start-up that can be leveraged to bring the disrupting agility needed by organizations.
Being a greenfield organization, it is born with clear principles in place to serve the purpose of continuous delivery, avoiding any obstructive blocks/silos.</p>

<p>With a background of many years in the IT industry, <b>Nubizzi</b> has the business knowledge and technical skills to radically transform and accelerate the way that organizations deliver software and services.
But the most important aspect is the capability to <b>inject innovation at fast speed into the solutions</b>, driven by business, to be at the top of the game.</p>

<p>We believe that we can offer a new operating model for IT departments to deliver software at speed, bringing together several cutting-edge technologies and practices: DevOps, containers, cloud-native application development, infrastructure as a software... This enables innovation which drives competitive advantage. Our solutions are proven to achieve greater customer engagement and unlock new revenue streams, by accelerating software delivery capability.</p>

<p>We work in partnership with our clients to define, build and deploy new services and ensure that they are aligned with their business transformation objectives and priorities. We are open to different business collaboration models, but we promote models where we shared costs, risks, and benefits with our customers to achieve real win-win partnerships.
	</p>
                    <header className="major">
                        <h2>Background</h2>
                    </header>
<p>Prior to founding <b>Nubizzi</b>, Miguel Cuervo had more than 22 years in the Information Technology industry, with a
wide range of experiences. He has served in both management and technical
roles.</p> 

<p>Most of his career has been in <a href="https://www.cgi.com/">CGI</a> where, after 20 years, he left the
company with the role of Vice President.</p>

<p>His technical experience has been related with the definition, design, development, and implementation of customized applications, especially in the demanding Telecommunication’s industry where he led the implementation of several billing, customer care, and workflow engines.
</p>

<p>Despite the official management role, he always played the role of the architect in CGI Spain for any technical matter in software development. He has a strong experience in real-time system, web applications for large call centers, performance tuning and system integration with third parties.
</p>

<p>His management experience comes from the leading of several vertical sectors where he was in charge of both sales and delivery with more than 450 employees under his structure.
</p>

<p>During the last year in CGI he was in charge of a new IP selling and delivery practice in CGI Southern Europe.</p>  
                 </div>
            </section>

    </div>
    </Layout>
)

export default About
